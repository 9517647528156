import type { Theme } from "@mui/material";
import { alpha, darkScrollbar, getContrastRatio } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Inter } from "next/font/google";

import { RiskRatingEnum } from "@/apis/services/HazardService";

const inter = Inter({ subsets: ["latin"] });

export const standardSectionSpacing = {
  margin: "5px 0px 8px",
  padding: "7px 0px 0px",
};
export const boxSpacing = {
  mt: 1,
  px: 2.0,
  py: 1.5,
};
export const truncationStyles = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "block",
};
export const sectionBoxStyles = {
  padding: "15px 15px 5px",
  margin: "7px 0px 0px",
  bgcolor: "#fff",
  borderRadius: 2,
};
export const assetHeaderStyles = {
  ...standardSectionSpacing,
  position: "sticky",
  top: 42,
  left: 0,
  zIndex: 3,
};
export const assetGridStyles = {
  containerStyle: standardSectionSpacing,
  gridStyle: {
    border: "none",
    paddingRight: 2,
    paddingLeft: 1.5,
  },
  columnHeaderHeight: 55,
  rowHeight: 40,
};
export const sidePadding = 8;

declare module "@mui/x-data-grid" {
  interface FooterPropsOverrides {
    ids: string[];
    setIds: (ids: string[]) => void;
    groupId?: string;
    handleRemoveSelectedAssets?: () => void;
    filters: any;
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
  }

  interface NoRowsOverlayPropsOverrides {
    type: string;
  }

  interface PaginationPropsOverrides {
    ids: string[];
    setIds: (ids: string[]) => void;
    disableNextPage: boolean;
    total?: number;
    editMode: boolean;
    setEditMode: (editMode: boolean) => void;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    toolTipLabel: React.CSSProperties;
    graphCaption: React.CSSProperties;
    assetCell: React.CSSProperties;
    body3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    toolTipLabel?: React.CSSProperties;
    graphCaption: React.CSSProperties;
    assetCell: React.CSSProperties;
    body3: React.CSSProperties;
  }

  interface Palette {
    neutral: Palette["primary"];
    muted: Palette["primary"];
    link: Palette["primary"];
  }

  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
    muted: PaletteOptions["primary"];
    link: PaletteOptions["primary"];
  }
}
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    toolTipLabel: true;
    graphCaption: true;
    assetCell: true;
    body3: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    muted: true;
  }
}

const buttonOverride = {
  root: ({ theme }: { theme: Theme }) => ({
    textTransform: undefined,
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    color: "inherit",

    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.05)",
    },
    "&.MuiButton-outlined": { "&:hover": { borderColor: "lightgrey" } },
    "&.MuiButton-contained": {
      boxShadow: "none",
    },
    "&.MuiButton-containedPrimary": {
      backgroundColor: "#3d3d3c",
      color: "white",
      boxShadow: "none",
      "&.Mui-disabled": {
        backgroundColor: "rgba(0, 0, 0, 0.12)",
        color: "rgba(0, 0, 0, 0.26)",
      },
    },
    "&.MuiButton-sizeSmall": {
      fontSize: theme.typography.pxToRem(15),
    },
    "&.MuiButton-sizeLarge": {
      fontSize: theme.typography.pxToRem(19),
    },
  }),
};

export const riskRatingPalette = {
  [RiskRatingEnum.Catastrophic]: { main: "#240505", contrastText: "#FFFFFF" },
  [RiskRatingEnum.VeryHigh]: { main: "#4D0F65", contrastText: "#FFFFFF" },
  [RiskRatingEnum.HighVeryHigh]: { main: "#880E0E", contrastText: "#FFFFFF" },
  [RiskRatingEnum.High]: { main: "#E51A1A", contrastText: "#FFFFFF" },
  [RiskRatingEnum.MediumHigh]: { main: "#E77E2F", contrastText: "#FFFFFF" },
  [RiskRatingEnum.Medium]: { main: "#FFDA47", contrastText: "#FFFFFF" },
  [RiskRatingEnum.LowMedium]: { main: "#BDD372", contrastText: "#FFFFFF" },
  [RiskRatingEnum.Low]: { main: "#66A74F", contrastText: "#FFFFFF" },
  [RiskRatingEnum.VeryLow]: { main: "#59dbf2", contrastText: "#FFFFFF" },
  [RiskRatingEnum.Negligible]: { main: "#8CEEFB", contrastText: "#FFFFFF" },
  [RiskRatingEnum.Plausible]: { main: "#A6F7FF", contrastText: "#FFFFFF" },
  [RiskRatingEnum.NotAssessed]: { main: "#b3b3b3", contrastText: "#FFFFFF" },
  "Not Available": { main: "#b3b3b3", contrastText: "#FFFFFF" },
};

const riskRatingNumberPalette = {
  DEFAULT: { main: "#9e9e9e", contrastText: "#FFFFFF" },
  RISK_RATING_10: { main: "#240505", contrastText: "#FFFFFF" },
  RISK_RATING_9: { main: "#240505", contrastText: "#FFFFFF" },
  RISK_RATING_8: { main: "#4D0F65", contrastText: "#FFFFFF" },
  RISK_RATING_7: { main: "#880E0E", contrastText: "#FFFFFF" },
  RISK_RATING_6: { main: "#E51A1A", contrastText: "#FFFFFF" },
  RISK_RATING_5: { main: "#E77E2F", contrastText: "#FFFFFF" },
  RISK_RATING_4: { main: "#FFDA47", contrastText: "#FFFFFF" },
  RISK_RATING_3: { main: "#BDD372", contrastText: "#FFFFFF" },
  RISK_RATING_2: { main: "#66A74F", contrastText: "#FFFFFF" },
  RISK_RATING_1: { main: "#59dbf2", contrastText: "#FFFFFF" },
  RISK_RATING_0: { main: "#3288A1", contrastText: "#FFFFFF" },
  RISK_RATING_NA: { main: "#b3b3b3", contrastText: "#FFFFFF" },
};

const hazardPalette = {
  FLOOD: { main: "rgba(0, 0, 255, 1)", contrastText: "#FFFFFF" },
  TSUNAMI: { main: "rgba(0, 0, 255, 1)", contrastText: "#FFFFFF" },
  WILDFIRE: { main: "#E77E2F", contrastText: "#FFFFFF" },
  WIND: { main: "#66A74F", contrastText: "#FFFFFF" },
  LANDSLIDE: { main: "#66A74F", contrastText: "#FFFFFF" },
  AVALANCHE: { main: "#66A74F", contrastText: "#FFFFFF" },
  SEISMIC: { main: "#880E0E", contrastText: "#FFFFFF" },
  TORNADO: { main: "#9c27b0", contrastText: "#FFFFFF" },
  HEAT: { main: "#E51A1A", contrastText: "#FFFFFF" },
  THUNDERSTORM: { main: "#4D0F65", contrastText: "#FFFFFF" },
  VOLCANO: { main: "#240505", contrastText: "#FFFFFF" },
  INTENTIONAL: { main: "#240505", contrastText: "#FFFFFF" },
  ICE_STORM: { main: "#59dbf2", contrastText: "#FFFFFF" },
  COLD: { main: "#59dbf2", contrastText: "#FFFFFF" },
  SNOW: { main: "#59dbf2", contrastText: "#FFFFFF" },
  ADJACENCY: { main: "#E77E2F", contrastText: "#FFFFFF" },
  ACCIDENTAL: { main: "#E77E2F", contrastText: "#FFFFFF" },
  HAIL: { main: "#3288A1", contrastText: "#FFFFFF" },
  DROUGHT: { main: "#BDD372", contrastText: "#FFFFFF" },
  DUST_STORM: { main: "#BDD372", contrastText: "#FFFFFF" },
};

const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 2400,
  },
};

export const getAppStyle = (
  color: { primary: string; secondary: string } = {
    primary: "rgb(230, 30, 41)",
    secondary: "rgb(0, 0, 0)",
  },
  mode: "light" | "dark"
) => {
  return {
    breakpoints,
    palette: {
      mode,
      primary: { main: color.primary, light: grey[500] },
      secondary: { main: color.secondary },
      neutral: { main: "#ffffff", contrastText: "rgba(0,0,0,.87)" },
      success: { main: "rgb(69, 161, 64)" },
      muted: {
        main: alpha("#EAEAEA", 0.7),
        light: alpha("#EAEAEA", 0.5),
        dark: alpha("#EAEAEA", 0.9),
        contrastText:
          getContrastRatio(alpha("#EAEAEA", 0.7), "#fff") > 4.5
            ? "#fff"
            : "#111",
      },
      link: { main: "rgba(117, 144, 161)" },
      ...riskRatingPalette,
      ...riskRatingNumberPalette,
      ...hazardPalette,
    },
    typography: {
      button: {
        textTransform: "none",
      },
      allVariants: {
        fontFamily: inter.style.fontFamily,
      },
      h1: {
        fontSize: "2rem",
        lineHeight: 1.235,
        fontWeight: 500,
      },
      h2: {
        fontSize: "1.5rem",
        fontWeight: "bold",
        lineHeight: "120%",
      },
      h3: {
        fontSize: "1.25rem",
        fontWeight: "bold",
        lineHeight: "143%",
      },
      h4: {
        fontSize: "1.1rem",
        fontWeight: 500,
      },
      h5: {
        fontSize: "1rem",
        fontWeight: 500,
      },
      h6: {
        fontSize: "1rem",
        fontWeight: 500,
      },
      subtitle2: {
        fontWeight: "bold",
      },
      overline: {
        fontSize: "1rem",
      },
      body1: {
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
      },
      caption: {
        fontSize: "0.85rem",
        lineHeight: "1.3rem",
        fontStyle: "italic",
      },
      body2: {
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "150%",
      },
      toolTipLabel: {
        fontWeight: "bold",
        fontSize: 14,
        maxWidth: "inherit",
        fontFamily: "inherit",
        ...truncationStyles,
      },
      graphCaption: {
        fontSize: "0.875rem",
        maxWidth: "inherit",
        ...truncationStyles,
      },
      assetCell: {
        fontSize: "0.875rem",
        fontWeight: 400,
        ...truncationStyles,
      },
      body3: {
        fontSize: "1.3rem",
        fontWeight: "bold",
        lineHeight: "120%",
      },
    },
    components: {
      MuiButton: {
        styleOverrides: buttonOverride,
      },
      MuiLoadingButton: {
        styleOverrides: buttonOverride,
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "&:hover": {
              "&& fieldset": {
                borderColor: color.primary,
              },
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 40,
            fontSize: "1.8rem",
          },
        },
      },
      MuiTabs: {
        styleOverrides: {
          root: {
            "& .MuiTabs-indicator": {
              display: "none",
            },
            "& .Mui-selected": {
              color: "black",
            },
            "& .MuiTabs-scrollButtons": {
              opacity: 100,
              alignSelf: "flex-start",
              "&.Mui-disabled": {
                color: "rgba(0,0,0,0.38)",
                opacity: 23,
              },
            },
            "& .MuiTab-root": {
              minHeight: 35,
              height: 35,
              "&:hover": { color: "gray" },
            },
            "& .Mui-focusVisible": { display: "none" },
            "& .MuiTab-root:not(:first-of-type)": {},
          },
        },
        defaultProps: {
          allowScrollButtonsMobile: true,
        },
      },
      MuiTab: {
        defaultProps: {
          disableRipple: true,
        },
        styleOverrides: {
          root: {
            "&:hover": { color: color.primary, opacity: 0.5 },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: "rgba(0, 0, 0, 0.87)",
            fontSize: "0.875rem",
            fontWeight: 600,
            "&.MuiInputLabel-root": {
              color: "rgba(0, 0, 0, 0.55)",
            },
            "&.MuiInputLabel-root.MuiInputLabel-shrink": {
              color: "rgba(0, 0, 0, 0.87)",
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            color: "rgba(0, 0, 0, 0.87)",
          },
          head: {
            fontWeight: 700,
          },
        },
      },
      MuiCssBaseline: {
        "*": {
          scrollBehavior: "smooth",
        },
        styleOverrides: {
          html: {
            fontSize: 14,
          },
          body: darkScrollbar({
            track: "rgba(206, 206, 206, .4)",
            thumb: "rgba(179, 179, 179, .6)",
            active: "rgba(206, 206, 206, .4)",
          }),
          "*::-webkit-scrollbar": {
            width: "0.75em",
            height: "0.75em",
          },
        },
      },
      MuiDialogTitle: {
        styleOverrides: {
          root: {
            fontSize: "1.25rem",
          },
        },
      },
      MuiModal: {
        defaultProps: {
          disableScrollLock: true,
        },
      },
      MuiListItemButton: {
        styleOverrides: {
          root: {
            borderRadius: 10,
            "&.Mui-selected": {
              borderRadius: "10px",
              color: "rgba(0, 0, 0)",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
              fontWeight: "700",
              position: "relative",
            },
            ":hover": {
              borderRadius: "10px",
            },
            "&.Mui-selected:before": {
              content: '""',
              position: "absolute",
              top: "50%",
              transform: "translateY(-50%)",
              left: 0,
              width: "5px",
              height: "60%",
              backgroundColor: "rgba(204, 0, 0)",
              borderRadius: 10,
            },
          },
        },
      },
      MuiDivider: {
        styleOverrides: {
          root: {
            borderWidth: "1px",
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            ".MuiDataGrid-columnSeparator": {
              visibility: "hidden",
            },
            ".MuiDataGrid-columnHeaderTitleContainer": {
              paddingLeft: "0px !important",
            },
            ".MuiDataGrid-columnHeader:focus": {
              outline: "none !important",
            },
            ".MuiDataGrid-columnHeader:focus-within": {
              outline: "none !important",
            },
            ".MuiDataGrid-cell:focus": {
              outline: "none !important",
            },
            ".MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            ".MuiDataGrid-cell": {
              cursor: "pointer",
            },
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            ".MuiDataGrid-columnSeparator": {
              visibility: "hidden",
            },
            ".MuiDataGrid-columnHeaderTitleContainer": {
              paddingLeft: "0px !important",
            },
            ".MuiDataGrid-columnHeader:focus": {
              outline: "none !important",
            },
            ".MuiDataGrid-columnHeader:focus-within": {
              outline: "none !important",
            },
            ".MuiDataGrid-cell:focus": {
              outline: "none !important",
            },
            ".MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            ".MuiDataGrid-cell": {
              cursor: "pointer",
            },
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            "&:last-of-type": {
              borderRadius: 0,
            },
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            "&:last-child": {
              paddingBottom: 8,
            },
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            fontSize: 14,
          },
        },
      },
      MuiCardHeader: {
        styleOverrides: {
          title: {
            fontSize: 24,
          },
        },
      },
      MuiAvatar: {
        styleOverrides: {
          root: {
            fontFamily: inter.style.fontFamily,
            fontWeight: 500,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            fontFamily: inter.style.fontFamily,
            fontWeight: 500,
            "& .MuiAutocomplete-tag": {
              fontWeight: 500,
            },
          },
          sizeMedium: {
            fontSize: "0.875rem",
            fontWeight: 600,
          },
        },
      },
    },
  };
};
